import * as React from "react";
import Container from "../components/Container/Container";
import TextLayout from "../layouts/TextLayout";

const PrivacyPolicyPage = () => {
  return (
    <TextLayout
      pageTitle={"Privacy Policy | Omnia"}
      headerFixedOnMobile
      hideAdditionalSection
      title={"Privacy Policy"}
    >
      <Container textLayout>
        <div className="inner-page-wrap__content">
          <p>
            Privacy Infrastructure Provider OÜ or any related entity (the
            "Company," "we," or "us") would like to inform you on how we
            collect, use and disclose information. This Privacy Policy describes
            our practices in connection with information that we collect through
            websites operated by us from which you are accessing this Privacy
            Policy (the "Websites"); which may be accessed through
            HTML-formatted email message(s) that we send to you that link to
            this Privacy Policy. Collectively, we refer to the above as the
            "Service."
          </p>
          <p>
            We take the protection of your personal data very seriously. By
            using the Service, you agree to the collection and use of
            information in accordance with this policy. We collect several
            different types of information for various purposes to provide and
            improve our Service to you.
          </p>
          <h2>Contact Email</h2>
          <p>
            When you send us an email we will store your provided personal
            information in order to process your inquiry and to provide you with
            our services to the extent applicable. We will keep copies of such
            communication as long as it is necessary for dealing with your
            inquiry.
          </p>
          <h2>Tracking & Cookies Data</h2>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information. Cookies are
            files with a small amount of data that our website stores on your
            device to collect and track information and to improve and analyze
            our Service.
          </p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may occasionally revise our privacy policy by posting the changes
            here.
          </p>
          <p>
            By accessing any part of the Websites or Service, you agree that you
            are providing your express consent to the terms and conditions of
            this Privacy Policy.
          </p>
          <h2>Contact Details</h2>
          <p>
            If you have further questions with regards to this privacy policy do
            not hesitate to contact us at contact@omniatech.io with the subject
            line "Privacy Policy".
          </p>
        </div>
      </Container>
    </TextLayout>
  );
};

export default PrivacyPolicyPage;
